// gloabl variable declrations
$screen-height: 1080px;
$screen-width: 1920px;
$dialog-width: 972px;
$title-width: 720px;
$title-height: 48px;
$sub-text-width: 100px;
$sub-text-height: $title-height;
$divider-width: 864px;
$divider-height: 3px;
$description-width: $divider-width;
$description-height: auto;
$button-width: $divider-width;
$button-height: 75px;
$button-text-width: 43px;
$button-text-height: 46px;
$half-screen-height: 540px;

// Playback Controls
$playback-controls-text-width: 521px;
$playback-controls-text-height: 46px;
$playback-controls-text-bottom: 71px;
$playback-controls-text-left: 700px;
$action-strip-right: 100px;
$action-strip-height: 80px;
$action-item-right: 20px;
$action-strip-container-height: 100px;
$closed-captions-bottom: 40px;
$closed-captions-padding: 9px 16.5px 9px 14px;
$closed-captions-span-width: 37.5px;
$closed-captions-span-height: 39px;
$closed-captions-check-mark-dimension: 21px;
$closed-captions-check-mark-margin-right: 6px;

// progress bar
$current-time-container-bottom: 165px;
$current-time-container-left: 196.5px;
$current-time-container-width: 0;
$current-time-play-button-margin-left: 15px;
$current-time-play-button-height: 0;
$current-time-play-button-width: 0;
$current-position-indicator-height: 24px;
$current-position-indicator-width: 6px;
$progress-bar-start-time-height: 46px;
$progress-bar-start-time-right: 1742px;
$progress-bar-start-time-bottom: 147px;
$progress-bar-with-progress-marks-bottom: 165px;
$progress-bar-height: 9px;
$progress-nar-width: 1527px;
$progress-bar-left: 195px;
$progress-bar-bottom: 0;
$progress-marks-left: 195px;
$progress-marks-width: 1527px;
$progress-marks-bottom: 16.5px;
$progress-bar-end-time-width: 108px;
$progress-bar-end-time-height: 46px;
$progress-bar-end-time-right: 78px;
$progress-bar-end-time-bottom: 147px;
$progress-indicator-height: 21px;
$progress-indicator-width: 3px;

//standard infobanner
$info-standardbg-width: 1920px;
$info-standardbg-height: 300px;
$info-standardbg-top: 0;

$info-left-container-left: 0;
$info-left-container-top: 0;

$info-title-width: 896px;
$info-title-min-height: 60px;
$info-title-max-height: 120px;
$info-title-margin-left: 90px;
$info-title-margin-top: 42px;

$shrink-info-title-max-height: 110px;

$info-subtitle-width: 383px;
$info-subtitle-height: 45px;
$info-subtitle-margin-top: 9px;

$info-channel-logo-below-subtitle-width: 168px;
$info-channel-logo-below-subtitle-top: 167px;
$info-channel-logo-below-subtitle-margin-top: 8px;

$info-channel-logo-below-title-width: 168px;
$info-channel-logo-below-title-top: 120px;
$info-channel-logo-below-title-margin-top: 8px;

$info-brandingicon-right: 102px;
$info-brandingicon-top: 72px;
$info-brandingicon-width: 171px;
$info-brandingicon-height: 30px;

$info-clock-height: 46px;
$info-clock-top: 113px;
$info-clock-right: 102px;

$full-info-metadata-left: 90px;
$full-info-metadata-top: 16px;
$full-info-metadata-height: 45px;
$full-info-metadata-seperator-margin-top: 0;
$full-info-metadata-seperator-width: 10px;
$full-info-metadata-margin-left-right: 12px;
$full-info-metadata-rating-left: 17px;
$full-info-description-top: 14px;
$full-info-description-width: 896px;
$full-info-description-max-height: 495px;
$full-info-channel-icon-top: 36px;
$full-info-channel-icon-margin-bottom: 15px;
$full-info-critic-rating-width: 32px;
$full-info-critic-rating-percentage-width: 16px;
$full-info-critic-rating-percentage-height: 36px;
$full-info-tmdb-width: 74px;
$full-info-tmdb-height: 16px;
$full-info-release-year-width: 63px;
$full-info-channel-icon-width: 168px;
$full-info-channel-icon-height: 48px;
$full-info-percentage-top: 0;
$full-info-percentage-right: 8px;
$full-info-percentage-bottom: 9px;
$full-info-percentage-left: 33px;
$full-info-tmdb-top: 15px;
$full-info-tmdb-right: 0;
$full-info-tmdb-bottom: 14px;
$full-info-tmdb-left: 8px;
$full-info-release-year-top-bottom: 0;
$full-info-release-year-right-left: 12px;
$full-info-margin-zero: 0;
$full-info-rating-height: 28px;
$full-info-rating-width: 66px;
$full-info-branding-icon-margin: 11px;

//Selection Dialog
$selection-dialog-width: 768px;
$selection-dialog-content-width: 660px;
$selection-dialog-content-height: 600px;
$selection-dialog-options-border-radius: 9px;
$selection-dialog-options-width: 337px;
$selection-dialog-title-font-size: 42px;
