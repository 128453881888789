@import '../../assets/scss/colors';

.inital-welcome-container {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  &.welcome {
    background: url('./assets/generic.jpg') no-repeat;
    background-size: contain;
    background-color: $primary-background-color;
  }

  &.welcome.welcome-active {
    opacity: 1;
    transition: all 250ms linear;
  }

  &.welcome.hidden {
    opacity: 0;
    transition: all 500ms linear;
  }
}
