@import '../../assets/scss/colors';
@import '../../assets/scss/dimensions';

.shaka-player-container {
  width: $screen-width !important;
  height: $screen-height !important;
  position: fixed;
  top: 0;
  left: 0;

  .shaka-player {
    height: 100%;
    width: 100%;
    background-color: $primary-background-color;
  }

  .shaka-text-container {
    height: 100%;
    width: 100%;
    position: absolute;
    font-size: 4.4vmin;

    /* Make sure the text container doesn't steal pointer events from another
    * layer, such as the ad container.  There is nothing interactive in this
    * layer, so this should be fine. */
    pointer-events: none;

    /* Place the text container on the bottom of the video container. */
    bottom: 0%;
    width: 100%;
    min-width: 48px;

    /* When the controls fade in or out, it takes 600ms. Thus, when the text
      * container adjusts to the presence or absence of controls, we should wait
      * briefly, so the captions don't end up appearing behind the controls.
      * Instead of being a gradual animation, this is a fast animation with a
      * significant delay, since the captions moving around is a little
      * distracting. */
    transition: bottom cubic-bezier(0.4, 0, 0.6, 1) 100ms;
    transition-delay: 500ms;

    /* These are defaults which are overridden by JS or cue styles. */
    span:not(.shaka-text-wrapper) {
      display: inline;
      font-size: 20px;
      line-height: 1.4; // relative to font size.
      background-color: rgba(0, 0, 0, 0.8);
      color: rgb(255, 255, 255);
    }

    span:shaka-text-wrapper {
      display: inline;
      background: none;
    }
  }
}
